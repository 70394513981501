/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Qrcode from 'vux/src/components/qrcode';
export default {
  components: {
    Qrcode: Qrcode
  },
  data: function data() {
    return {
      orderId: '',
      //订单的id
      mealId: '',
      //餐厅的id
      orderInfo: {},
      //订单信息
      value: {}
    };
  },
  created: function created() {
    this.orderId = this.$route.query.orderId;
    this.mealId = this.$route.query.mealId;
    this.getOrderDetail();
  },
  methods: {
    //获取当餐的信息---2020-6-29  lwt
    getOrderDetail: function getOrderDetail() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.Meal.getOrderDetail(this.orderId, this.mealId).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.orderInfo = data;
          _this.value = {
            code: data.orderId
          };
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },

    /** @function   所以要判断时间
     *     lwt
     *   2020-07-07
     **/
    inspectDate: function inspectDate(date, time) {
      // date 是就餐时间  time 是就餐截至时间
      //明天的时间
      // date ='2020-07-08'
      // time='17:39'
      //在就餐时间之前-返回true  就餐时间之后  返回false
      time = time + ':00';
      date = date.replace(/-/g, '/');
      var orderDate = date + ' ' + time;
      orderDate = new Date(orderDate).getTime();
      var now = new Date().getTime();
      return orderDate - now > 0;
    }
  }
};